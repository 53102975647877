/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Button } from '@saleshandy/design-system';

import type { IProps } from './connect-smtp-imap-account-modal-container';
import {
  EmailProviderDropDown,
  LastTestConnection,
  SmtpImapProviders,
  TestConnection,
} from './types';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import { Variant } from '../../../../../../shared/design-system/components/atoms/button/button';
import Tips from './helper/tips';
import { StatusBadgeVariant } from '../../../../../../shared/design-system/components/molecules/status-badge/types';
import Input from '../../../../../../shared/design-system/components/input/input';
import Select from '../../../../../../shared/design-system/components/select';
import InputNumber from '../../../../../../shared/design-system/components/input-number';
import RadioButtonGroup from '../../../../../../shared/design-system/components/radio-button-group';
import Switch, {
  Size,
} from '../../../../../../shared/design-system/components/atoms/switch';
import {
  emailProviderDropDown,
  encryptionOption,
  getDefaultSmtpImapValues,
  getEmailProviderValue,
  getIsButtonIsLoading,
  getIsButtonDisable,
  getVariantTypeForSmtpImap,
  isSMTPTestButtonDisable,
  isIMAPTestButtonDisable,
  getEmailServiceProvider,
  useSameEmailPasswordSwitchDisable,
  validateEmail,
  showTestConnectionSignal,
  isUseSameEmailPasswordToggleOn,
  setHotPortEncryptionValue,
  isConnectButtonDisable,
  checkEmailValidationBeforeTesting,
  checkEmailValidationBeforeConnecting,
  getDefaultValuesForSmtpImapProviders,
} from '../../utils/helpers';
import { SMTPIMAP } from '../../../../enums/smtp-or-imap';
import FormDetailsHeader from '../form-details-header/form-details-header';
import {
  getIMAPErrorTitleAndTroubleshoot,
  getSMTPErrorTitleAndTroubleshoot,
  handleSwitchFn,
  onConnectAndSaveFn,
  setFormFieldValues,
  testSmtpImapAccountConnectionFn,
  validateSenderName,
} from './helper/helper';
import { ValidationMsg } from './helper/validations';
import Icon from '../../../../../../shared/design-system/components/atoms/icon';

const ConnectSMTPIMAPAccountModal: React.FC<IProps> = ({
  show,
  onClose,
  isSMTPTestConnectionRequestPending,
  isIMAPTestConnectionRequestPending,
  isConnectAccountRequestPending,
  sendTestSMTPConnectionResponse,
  sendTestIMAPConnectionResponse,
  sendTestSMTPConnectionRequest,
  sendTestIMAPConnectionRequest,
  sendConnectSmtpImapAccountRequest,
  sendConnectSmtpImapResponse,
  sendGetSmtpImapAccountDetailsResponse,
  sendUpdateSmtpImapAccountDetailsRequest,
  emailAccountId,
  isUpdateSmtpImapAccountRequestPending,
  sendUpdateSmtpImapAccountDetailsResponse,
  agencyConfig,
  showErrorLogModal,
  selectGmailAsDefault = false,
  senderDetails,
}) => {
  const [username, setUsername] = useState<string>();
  const [emailProvider, setEmailProvider] = useState<{
    key: string;
    value: string;
  }>(null);
  const [senderName, setSenderName] = useState<string>(
    senderDetails?.name || '',
  );
  const [smtpEmailAddress, setSmtpEmailAddress] = useState<string>(
    senderDetails?.email || '',
  );
  const [smtpEmailPassword, setSmtpEmailPassword] = useState<string>('');
  const [smtpHost, setSmtpHost] = useState<string>('');
  const [smtpPort, setSmtpPort] = useState<number>(null);
  const [smtpEncryption, setSmtpEncryption] = useState<string>('');
  const [isUseDifferentUsername, setIsUseDifferentUsername] = useState<boolean>(
    false,
  );
  const [
    isUseSameEmailPasswordFromSMTP,
    setIsUseSameEmailPasswordFromSMTP,
  ] = useState<boolean>(false);
  const [imapEmailAddress, setImapEmailAddress] = useState<string>(
    senderDetails?.email || '',
  );
  const [imapEmailPassword, setImapEmailPassword] = useState<string>('');
  const [imapHost, setImapHost] = useState<string>('');
  const [imapPort, setImapPort] = useState<number>(null);
  const [imapEncryption, setImapEncryption] = useState<string>('');
  const [showSmtpPassword, setShowSmtpPassword] = useState<boolean>(false);
  const [showImapPassword, setShowImapPassword] = useState<boolean>(false);
  const [
    SMTPBadgeVariant,
    setSMTPBadgeVariant,
  ] = useState<StatusBadgeVariant>();
  const [
    IMAPBadgeVariant,
    setIMAPBadgeVariant,
  ] = useState<StatusBadgeVariant>();
  const [lastTestConnection, setLastTestConnection] = useState<string>('');
  const [
    isSmtpEmailAddressValid,
    setIsSmtpEmailAddressValid,
  ] = useState<boolean>();
  const [
    isImapEmailAddressValid,
    setIsImapEmailAddressValid,
  ] = useState<boolean>();
  const [isSenderNameValid, setIsSenderNameValid] = useState<boolean>();
  const [smtpError, setSmtpError] = useState(null);
  const [imapError, setImapError] = useState(null);
  const [smtpErrorLog, setSmtpErrorLog] = useState<string>('');
  const [imapErrorLog, setImapErrorLog] = useState<string>('');

  // Const Variable
  const isSMTPTestDisable = !isSMTPTestButtonDisable({
    useUsername: isUseDifferentUsername,
    username,
    smtpEmailAddress,
    smtpEmailPassword,
    smtpHost,
    smtpPort,
    smtpEncryption,
    senderName,
    emailProvider,
  });

  const isIMAPTestDisabled = !isIMAPTestButtonDisable(
    imapEmailAddress,
    imapEmailPassword,
    imapHost,
    imapPort,
    imapEncryption,
  );

  const isConnectAndSaveButtonDisable = isConnectButtonDisable(
    isSMTPTestDisable,
    isIMAPTestDisabled,
  );

  const changeEmailProvider = (option: EmailProviderDropDown) => {
    const {
      smtpHostValue,
      smtpPortValue,
      smtpEncryptionValue,
      imapHostValue,
      imapPortValue,
      imapEncryptionValue,
    } = getDefaultSmtpImapValues(option.value);
    setEmailProvider(option);
    setHotPortEncryptionValue(option, {
      smtpHostValue,
      smtpPortValue,
      smtpEncryptionValue,
      imapHostValue,
      imapPortValue,
      imapEncryptionValue,
      setSMTPHost: () => {
        setSmtpHost(smtpHostValue);
      },
      setSMTPPort: () => {
        setSmtpPort(smtpPortValue);
      },
      setSMTPEncryption: () => {
        setSmtpEncryption(smtpEncryptionValue);
      },
      setIMAPHost: () => {
        setImapHost(imapHostValue);
      },
      setIMAPPort: () => {
        setImapPort(imapPortValue);
      },
      setIMAPEncryption: () => {
        setImapEncryption(imapEncryptionValue);
      },
    });
  };

  const toggleShowSMTPPassword = () => {
    setShowSmtpPassword((preState) => !preState);
  };

  const toggleShowIMAPPassword = () => {
    setShowImapPassword((preState) => !preState);
  };

  const onChangeSMTPRadioButton = (value) => {
    setSmtpEncryption(value);
  };

  const onChangeIMAPRadioButton = (value) => {
    setImapEncryption(value);
  };

  const onShowErrorLog = (accountType: SMTPIMAP) => {
    showErrorLogModal(
      accountType,
      accountType === SMTPIMAP.SMTP ? smtpErrorLog : imapErrorLog,
    );
  };

  const resetEmailValidState = () => {
    setIsSmtpEmailAddressValid(false);
    setIsImapEmailAddressValid(false);
  };

  const testSmtpImapAccountConnection = (args: TestConnection) => {
    setLastTestConnection(LastTestConnection.Test);

    const isSmtpEmailValid = validateEmail(smtpEmailAddress);
    const isImapEmailValid = validateEmail(imapEmailAddress);

    checkEmailValidationBeforeTesting({
      isSmtpEmailValid,
      isImapEmailValid,
      requestFrom: args.requestFrom,
      isSMTPEmailNotValid: () => setIsSmtpEmailAddressValid(true),
      isIMAPEmailNotValid: () => setIsImapEmailAddressValid(true),
      onEmailIsValid: () => resetEmailValidState(),
    });

    testSmtpImapAccountConnectionFn({
      isSmtpEmailValid,
      isImapEmailValid,
      requestFrom: args.requestFrom,
      username: args.username,
      emailAddress: args.emailAddress,
      password: args.password,
      host: args.host,
      port: args.port,
      encryption: args.encryption,
      onSendTestSMTPConnectionRequest: (requestPayload) => {
        sendTestSMTPConnectionRequest(requestPayload);
      },
      onSendTestIMAPConnectionRequest: (requestPayload) => {
        sendTestIMAPConnectionRequest(requestPayload);
      },
    });
  };

  const handleSwitch = (change) => {
    setIsUseSameEmailPasswordFromSMTP(change);

    handleSwitchFn({
      change,
      username,
      onChangeToTrue: () => {
        setImapEmailAddress(smtpEmailAddress);
        setImapEmailPassword(smtpEmailPassword);
      },
      onChangeToFalse: () => {
        setImapEmailAddress('');
        setImapEmailPassword('');
      },
      onSetUsername: () => {
        setImapEmailAddress(username);
        setImapEmailPassword(smtpEmailPassword);
      },
    });
  };

  const onChangeUsername = (change) => {
    const isEmail = validateEmail(change);

    setUsername(change);

    if (isEmail) {
      setSmtpEmailAddress(isEmail?.input);
    }
  };

  const onBlurHandler = () => {
    validateSenderName(senderName, setIsSenderNameValid);
  };

  const resetState = () => {
    setEmailProvider(null);
    setSmtpEmailAddress('');
    setSmtpEmailPassword('');
    setSmtpHost('');
    setSmtpPort(null);
    setUsername('');
    setSenderName('');
    setSmtpEncryption('');
    setImapEmailAddress('');
    setImapEmailPassword('');
    setImapHost('');
    setImapPort(null);
    setImapEncryption('');
    setLastTestConnection('');
    setIsUseDifferentUsername(false);
    setIsUseSameEmailPasswordFromSMTP(false);
    setIsSmtpEmailAddressValid(false);
    setIsImapEmailAddressValid(false);
    setIsSenderNameValid(false);
    setSmtpError(null);
    setImapError(null);
  };

  const onConnectAndSave = (e) => {
    e.preventDefault();

    const emailServiceProvider = getEmailServiceProvider(
      emailProvider,
      smtpEmailAddress,
    );
    const isSmtpEmailValid = validateEmail(smtpEmailAddress);
    const isImapEmailValid = validateEmail(imapEmailAddress);

    checkEmailValidationBeforeConnecting({
      isSmtpEmailValid,
      isImapEmailValid,
      isSMTPEmailNotValid: () => {
        setIsSmtpEmailAddressValid(true);
      },
      isIMAPEmailNotValid: () => {
        setIsImapEmailAddressValid(true);
      },
      onEmailIsValid: () => {
        resetEmailValidState();
      },
    });

    onConnectAndSaveFn({
      isSmtpEmailValid,
      isImapEmailValid,
      fromName: senderName,
      smtp: {
        userName: username,
        emailAddress: smtpEmailAddress,
        password: smtpEmailPassword,
        host: smtpHost,
        port: smtpPort,
        encryption: smtpEncryption,
      },
      imap: {
        emailAddress: imapEmailAddress,
        password: imapEmailPassword,
        host: imapHost,
        port: imapPort,
        encryption: imapEncryption,
      },
      emailAccountId: emailAccountId || null,
      emailServiceProvider,
      onSendUpdateSmtpImapAccountDetailsRequest: (emailId, payload) => {
        setLastTestConnection(LastTestConnection.Update);
        sendUpdateSmtpImapAccountDetailsRequest(emailId, payload);
      },
      onSendConnectSmtpImapAccountRequest: (payload) => {
        setLastTestConnection(LastTestConnection.ConnectAndSave);
        sendConnectSmtpImapAccountRequest(payload);
      },
    });
  };

  // * useEffect
  useEffect(() => {
    const { variant, connectionErrorMessage } = getVariantTypeForSmtpImap({
      testConnectionResponse: sendTestSMTPConnectionResponse,
      connectSmtpImapResponse: sendConnectSmtpImapResponse,
      smtpImapAccountDetailsResponse: sendGetSmtpImapAccountDetailsResponse,
      updateSmtpImapAccountDetailsResponse: sendUpdateSmtpImapAccountDetailsResponse,
      requestFrom: SMTPIMAP.SMTP,
      connectionType: 'smtpConnection',
      lastTestConnection,
    });

    setSMTPBadgeVariant(variant);

    if (variant === StatusBadgeVariant.DANGER && connectionErrorMessage) {
      setSmtpError(getSMTPErrorTitleAndTroubleshoot(connectionErrorMessage));
      setSmtpErrorLog(connectionErrorMessage);
    }
  }, [
    sendTestSMTPConnectionResponse,
    sendGetSmtpImapAccountDetailsResponse,
    sendUpdateSmtpImapAccountDetailsResponse,
    sendConnectSmtpImapResponse,
  ]);

  useEffect(() => {
    const { variant, connectionErrorMessage } = getVariantTypeForSmtpImap({
      testConnectionResponse: sendTestIMAPConnectionResponse,
      connectSmtpImapResponse: sendConnectSmtpImapResponse,
      smtpImapAccountDetailsResponse: sendGetSmtpImapAccountDetailsResponse,
      updateSmtpImapAccountDetailsResponse: sendUpdateSmtpImapAccountDetailsResponse,
      requestFrom: SMTPIMAP.IMAP,
      connectionType: 'imapConnection',
      lastTestConnection,
    });

    setIMAPBadgeVariant(variant);

    if (variant === StatusBadgeVariant.DANGER && connectionErrorMessage) {
      setImapError(getIMAPErrorTitleAndTroubleshoot(connectionErrorMessage));
      setImapErrorLog(connectionErrorMessage);
    }
  }, [
    sendTestIMAPConnectionResponse,
    sendGetSmtpImapAccountDetailsResponse,
    sendUpdateSmtpImapAccountDetailsResponse,
    sendConnectSmtpImapResponse,
  ]);

  useEffect(() => {
    setFormFieldValues({
      sendGetSmtpImapAccountDetailsResponse,
      setValues: () => {
        const { imap, smtp } = sendGetSmtpImapAccountDetailsResponse;

        const emailProviderValue = getEmailProviderValue(smtp.host);
        const isEmailAddressAndPasswordSame = isUseSameEmailPasswordToggleOn(
          smtp?.userName,
          smtp.emailAddress,
          smtp.password,
          imap.emailAddress,
          imap.password,
        );

        setSenderName(sendGetSmtpImapAccountDetailsResponse.senderName);
        setEmailProvider(emailProviderValue);
        // SMTP
        setSmtpEmailAddress(smtp.emailAddress);
        setSmtpEmailPassword(smtp.password);
        setSmtpHost(smtp.host);
        setSmtpPort(smtp.port);

        // IMAP
        setImapEmailAddress(imap.emailAddress);
        setImapEmailPassword(imap.password);
        setImapHost(imap.host);
        setImapPort(imap.port);
        setIsUseSameEmailPasswordFromSMTP(isEmailAddressAndPasswordSame);
      },
      setSMTPEncryptionValue: () => {
        const { smtp } = sendGetSmtpImapAccountDetailsResponse;

        setSmtpEncryption(smtp.encryption);
      },
      setIMAPEncryptionValue: () => {
        const { imap } = sendGetSmtpImapAccountDetailsResponse;

        setImapEncryption(imap.encryption);
      },
      setSmtpUsernameValue: () => {
        const { smtp } = sendGetSmtpImapAccountDetailsResponse;

        setIsUseDifferentUsername(true);
        setUsername(smtp.userName);
      },
      resetValue: () => {
        resetState();
      },
    });
  }, [sendGetSmtpImapAccountDetailsResponse, show]);

  useEffect(() => {
    if (selectGmailAsDefault) {
      const {
        smtpHostValue,
        smtpPortValue,
        smtpEncryptionValue,
        imapHostValue,
        imapPortValue,
        imapEncryptionValue,
      } = getDefaultValuesForSmtpImapProviders(SmtpImapProviders.GmailGsuite);

      const emailProviderValue = getEmailProviderValue(smtpHostValue);
      setEmailProvider(emailProviderValue);

      // SMTP
      setSmtpHost(smtpHostValue);
      setSmtpPort(smtpPortValue);
      setSmtpEncryption(smtpEncryptionValue);

      // IMAP
      setImapHost(imapHostValue);
      setImapPort(imapPortValue);
      setImapEncryption(imapEncryptionValue);
    }
  }, [selectGmailAsDefault]);

  useEffect(() => {
    if (senderDetails) {
      if (senderDetails.name) {
        setSenderName(senderDetails.name);
      }
      if (senderDetails.email) {
        setSmtpEmailAddress(senderDetails.email);
        setImapEmailAddress(senderDetails.email);
      }
    }
  }, [senderDetails]);

  // * Render Functions
  const renderConnectionSuccessMessage = (badgeVariant, accountType) =>
    badgeVariant === StatusBadgeVariant.SUCCESS && (
      <div className="d-flex align-items-center regular-2 font-medium green-txt-14 mt-3">
        <Icon identifier="circle-check" />
        <span className="ml-2">{accountType} authenticated successfully!</span>
      </div>
    );

  return (
    <Modal
      show={show}
      onClose={onClose}
      onHide={onClose}
      onSubmit={(e) => onConnectAndSave(e)}
      className="connect-smtp-imap-account-modal"
      titleContent="Connect SMTP/IMAP account"
      showCloseIcon={true}
      submitButtonText="Connect & Save"
      submitButtonClassName="submit-btn"
      cancelButtonVarient={Variant.Outlined}
      isSubmitLoading={getIsButtonIsLoading(
        isConnectAccountRequestPending,
        isUpdateSmtpImapAccountRequestPending,
      )}
      isSubmitDisabled={getIsButtonDisable(
        isConnectAndSaveButtonDisable,
        isConnectAccountRequestPending,
        isUpdateSmtpImapAccountRequestPending,
        isSenderNameValid,
      )}
      extraModalProps={{
        'aria-labelledby': 'contained-modal-title-vcenter',
        centered: true,
        backdrop: 'static',
      }}
    >
      <div className="add-smtp-imap-wrapper d-flex flex-column">
        <div className="gray-1 bs-border-radius-8 p-3 d-flex justify-content-between">
          <div className="form-wrapper">
            <div className="smtp-details-form">
              <div className="form-header-wrapper d-flex justify-content-between">
                <FormDetailsHeader
                  title="SMTP Details"
                  subTitle="to send your email"
                  requestFrom={SMTPIMAP.SMTP}
                  agencyConfig={agencyConfig}
                />
              </div>

              {/* SMTP Form */}
              <div className="switch-wrap">
                <Switch
                  checked={isUseDifferentUsername}
                  label="Use different username"
                  onChange={(change) => setIsUseDifferentUsername(change)}
                  size={Size.Small}
                  className="smtp-imap-switch use-username"
                />
              </div>

              <div className="form-container d-grid">
                {isUseDifferentUsername && (
                  <>
                    <div className="form-item">
                      <Input
                        label="Username"
                        placeholder="Username"
                        className="form-control-input"
                        value={username}
                        onChange={(change) => onChangeUsername(change)}
                      />
                    </div>
                    <div className="form-item" />
                  </>
                )}
                <div className="form-item">
                  <span className="semibold-1 gray-txt-15">Email Provider</span>

                  <div className="email-provider-dropdown">
                    <Select<EmailProviderDropDown>
                      options={emailProviderDropDown}
                      placeholder="Select email service provider"
                      selectedOptionKey={emailProvider?.key || null}
                      optionRenderer={(option) => <span>{option?.value}</span>}
                      showOptionsSeparator={true}
                      selectedOptionRenderer={([option]) => (
                        <span>{option?.value}</span>
                      )}
                      onChange={([option]) => changeEmailProvider(option)}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <Input
                    label="Sender Name"
                    placeholder="Display Name"
                    className="form-control-input"
                    value={senderName}
                    onChange={(e) => setSenderName(e)}
                    onBlur={onBlurHandler}
                  />
                  <ValidationMsg
                    validCondition={isSenderNameValid}
                    msg="messages.sender_name_limit_error"
                  />
                </div>
                <div className="form-item">
                  <Input
                    label="Email Address"
                    placeholder="email@address.com"
                    className="form-control-input"
                    type="email"
                    value={smtpEmailAddress}
                    onChange={(e) => setSmtpEmailAddress(e)}
                  />
                  <ValidationMsg
                    validCondition={isSmtpEmailAddressValid}
                    msg="messages.please_enter_valid_email"
                  />
                </div>
                <div className="form-item">
                  <Input
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type={showSmtpPassword ? 'text' : 'password'}
                    value={smtpEmailPassword}
                    onChange={(e) => setSmtpEmailPassword(e)}
                    autoComplete="login-password"
                    icons={[
                      {
                        place: Input.IconPlace.Right,
                        identifier: showSmtpPassword ? 'eye-alt' : 'eye',
                        className: 'pointer',
                        onClick: toggleShowSMTPPassword,
                      },
                    ]}
                    className="form-control-input"
                  />
                </div>
                <div className="form-item">
                  <Input
                    label="SMTP Host"
                    placeholder="host.email.com"
                    className="form-control-input"
                    disabled={emailProvider?.key !== SmtpImapProviders.Other}
                    value={smtpHost}
                    onChange={(e) => setSmtpHost(e)}
                  />
                </div>
                <div className="form-item">
                  <div className="port-input-wrap form-control-input">
                    <InputNumber
                      label="SMTP Port"
                      placeholder="123"
                      className="port-input"
                      value={smtpPort}
                      onChange={(e) => setSmtpPort(+e)}
                    />
                  </div>

                  <div className="encryption-radio-group-wrap">
                    <span className="semibold-1 gray-txt-15">Encryption</span>

                    <div className="encryption-radio-group">
                      <RadioButtonGroup
                        name="options"
                        options={encryptionOption}
                        variant={RadioButtonGroup.Variant.Outlined}
                        value={smtpEncryption}
                        onChange={(value) => onChangeSMTPRadioButton(value)}
                        className="encryption-radio-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tips-wrapper">
            <Tips
              accountType={SMTPIMAP.SMTP}
              isConnectionError={showTestConnectionSignal({
                sendTestConnectionResponse: sendTestSMTPConnectionResponse,
                sendConnectSmtpImapResponse,
                sendGetSmtpImapAccountDetailsResponse,
                sendUpdateSmtpImapAccountDetailsResponse,
                badgeVariant: SMTPBadgeVariant,
              })}
              errorDetails={smtpError}
              onShowErrorLog={() => onShowErrorLog(SMTPIMAP.SMTP)}
              selectGmailAsDefault={
                emailProvider?.value === SmtpImapProviders.GmailGsuite
              }
            />

            <div className="test-email-section mt-3">
              <Button
                variant="secondary"
                onClick={() =>
                  testSmtpImapAccountConnection({
                    username,
                    emailAddress: smtpEmailAddress,
                    password: smtpEmailPassword,
                    host: smtpHost,
                    port: smtpPort,
                    encryption: smtpEncryption,
                    requestFrom: SMTPIMAP.SMTP,
                  })
                }
                isLoading={isSMTPTestConnectionRequestPending}
                disabled={
                  isSMTPTestConnectionRequestPending || isSMTPTestDisable
                }
              >
                Test SMTP Connection
              </Button>

              {renderConnectionSuccessMessage(SMTPBadgeVariant, SMTPIMAP.SMTP)}
            </div>
          </div>
        </div>

        <div className="gray-1 bs-border-radius-8 p-3 mt-2 d-flex justify-content-between">
          <div className="form-wrapper">
            <div className="imap-details-form">
              <div className="form-header-wrapper d-flex justify-content-between">
                <FormDetailsHeader
                  title="IMAP Details"
                  subTitle="to track your email"
                  requestFrom={SMTPIMAP.IMAP}
                  agencyConfig={agencyConfig}
                />
              </div>

              <div className="switch-wrap">
                <Switch
                  checked={isUseSameEmailPasswordFromSMTP}
                  label="Use the same username and password from SMTP"
                  onChange={(change) => handleSwitch(change)}
                  size={Size.Small}
                  className="smtp-imap-switch"
                  disabled={useSameEmailPasswordSwitchDisable(
                    username,
                    smtpEmailAddress,
                    smtpEmailPassword,
                  )}
                />
              </div>
              <div className="form-container d-grid">
                <div className="form-item">
                  <Input
                    label="Email Address"
                    placeholder="email@address.com"
                    className="form-control-input"
                    type="email"
                    value={imapEmailAddress}
                    onChange={(e) => setImapEmailAddress(e)}
                  />
                  <ValidationMsg
                    validCondition={isImapEmailAddressValid}
                    msg="messages.please_enter_valid_email"
                  />
                </div>
                <div className="form-item">
                  <Input
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type={showImapPassword ? 'text' : 'password'}
                    value={imapEmailPassword}
                    onChange={(e) => setImapEmailPassword(e)}
                    autoComplete="login-password"
                    icons={[
                      {
                        place: Input.IconPlace.Right,
                        identifier: showImapPassword ? 'eye-alt' : 'eye',
                        className: 'pointer',
                        onClick: toggleShowIMAPPassword,
                      },
                    ]}
                    className="form-control-input"
                  />
                </div>
                <div className="form-item">
                  <div className="port-input-wrap form-control-input">
                    <Input
                      label="IMAP Host"
                      placeholder="host.email.com"
                      className="form-control-input"
                      disabled={emailProvider?.key !== SmtpImapProviders.Other}
                      value={imapHost}
                      onChange={(e) => setImapHost(e)}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <div className="port-input-wrap form-control-input">
                    <InputNumber
                      label="IMAP Port"
                      placeholder="123"
                      className="port-input"
                      value={imapPort}
                      onChange={(e) => setImapPort(+e)}
                    />
                  </div>

                  <div className="encryption-radio-group-wrap">
                    <span className="semibold-1 gray-txt-15">Encryption</span>

                    <div className="encryption-radio-group">
                      <RadioButtonGroup
                        name="options"
                        options={encryptionOption}
                        variant={RadioButtonGroup.Variant.Outlined}
                        value={imapEncryption}
                        onChange={(value) => onChangeIMAPRadioButton(value)}
                        className="encryption-radio-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tips-wrapper">
            <Tips
              accountType={SMTPIMAP.IMAP}
              isConnectionError={showTestConnectionSignal({
                sendTestConnectionResponse: sendTestIMAPConnectionResponse,
                sendConnectSmtpImapResponse,
                sendGetSmtpImapAccountDetailsResponse,
                sendUpdateSmtpImapAccountDetailsResponse,
                badgeVariant: IMAPBadgeVariant,
              })}
              errorDetails={imapError}
              onShowErrorLog={() => onShowErrorLog(SMTPIMAP.IMAP)}
              selectGmailAsDefault={
                emailProvider?.value === SmtpImapProviders.GmailGsuite
              }
            />
            <div className="test-email-section mt-3">
              <div>
                <Button
                  variant="secondary"
                  className="test-btn"
                  onClick={() =>
                    testSmtpImapAccountConnection({
                      emailAddress: imapEmailAddress,
                      password: imapEmailPassword,
                      host: imapHost,
                      port: imapPort,
                      encryption: imapEncryption,
                      requestFrom: SMTPIMAP.IMAP,
                    })
                  }
                  isLoading={isIMAPTestConnectionRequestPending}
                  disabled={
                    isIMAPTestConnectionRequestPending || isIMAPTestDisabled
                  }
                >
                  Test IMAP Connection
                </Button>
              </div>
              {renderConnectionSuccessMessage(IMAPBadgeVariant, SMTPIMAP.IMAP)}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectSMTPIMAPAccountModal;
