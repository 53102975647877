import produce from 'immer';
import React from 'react';
import { Link } from 'react-router-dom';
import Alert from '../../../../shared/design-system/components/alert';
import Button from '../../../../shared/design-system/components/atoms/button';
import Input from '../../../../shared/design-system/components/input';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  getCookie,
  getCurrentTimeZone,
  initializeThirdPartyIntegrations,
  redirectWithoutRefresh,
} from '../../../../shared/utils';
import { executeOnRequestStatus } from '../../../../shared/utils/execute-on-request-status';
import AlertContainer from '../alert-container';
import ButtonContainer from '../button-container';
import FormSection from '../form-section';
import PrivacyPolicy from '../privacy-policy';
import TopWelcome from '../top-welcome';
import { IProps, IState } from './types';
import { validate } from './validator';

class SignupForm extends React.PureComponent<IProps, IState> {
  private readonly v3 = 'V3';

  constructor(props: IProps) {
    super(props);

    this.state = {
      values: {
        email: props.email ? props.email : '',
      },
      errors: {
        email: '',
      },
      dirty: {
        email: false,
      },
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.hideErrorHandler = this.hideErrorHandler.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      status,
      acceptInvitationRequestStatus,
      trackingId,
      firstName,
      lastName,
      email,
    } = this.props;

    if (
      acceptInvitationRequestStatus !== prevProps.acceptInvitationRequestStatus
    ) {
      executeOnRequestStatus({
        status: acceptInvitationRequestStatus,
        onSuccess: () => {
          initializeThirdPartyIntegrations({
            trackingId,
            firstName,
            lastName,
            email,
          });
        },
      });
    }

    if (status !== prevProps.status) {
      if (status === RequestStatus.Succeeded) {
        const { values } = this.state;
        const queryParams = new URLSearchParams('');
        queryParams.set('email', values.email);

        redirectWithoutRefresh(`/welcome?${queryParams.toString()}`);
      }
    }
  }

  componentWillUnmount() {
    const { hideError } = this.props;
    hideError?.();
  }

  onInputChange(value, e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { dirty, errors, values: errorValues } = this.state;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, errorValues[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    let timeZone = getCurrentTimeZone();
    if (timeZone === 'Asia/Calcutta') {
      timeZone = 'Asia/Kolkata';
    }
    const { values } = this.state;
    const { email } = values;
    const landingPage = getCookie('sh_ulp') || '';
    const { sendSignupRequest } = this.props;

    let rewardfulReferralId = '';
    if (window.rewardful) {
      rewardfulReferralId = window.Rewardful?.referral;
    }
    sendSignupRequest(email, landingPage, timeZone, rewardfulReferralId);
  }

  hideErrorHandler() {
    const { hideError } = this.props;
    hideError?.();
  }

  render() {
    const { values, errors } = this.state;
    const { status, error, showError, forInvitation } = this.props;
    const isLoading = status === RequestStatus.Pending;
    const isSignUpButtonDisable =
      !!validate('email', values.email) || isLoading;

    return (
      <div className="signup">
        <div>
          <TopWelcome>
            <TopWelcome.Header>Signup for free</TopWelcome.Header>
            <TopWelcome.Body>
              No obligations, no credit-card required.
            </TopWelcome.Body>
          </TopWelcome>
          {showError && (
            <AlertContainer>
              <Alert
                variant={Alert.Variant.Danger}
                dismissible={true}
                onClose={this.hideErrorHandler}
                iconIdentifier="close-o"
                header="Whoops"
              >
                {error.message}
              </Alert>
            </AlertContainer>
          )}
          <FormSection>
            <form
              onSubmit={this.onFormSubmit}
              className="signup-form signup step_1"
            >
              <Input
                disabled={forInvitation}
                name="email"
                label="Email"
                placeholder="Preferably your work email..."
                value={values.email}
                variant={errors.email && Input.Variant.Error}
                caption={errors.email}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                autoComplete="current-email"
              />

              <ButtonContainer>
                <Button
                  variant={Button.Variant.Primary}
                  type={Button.Type.Submit}
                  isLoading={isLoading}
                  disabled={isSignUpButtonDisable}
                >
                  {forInvitation ? 'Continue' : 'Sign Up'}
                </Button>
              </ButtonContainer>
            </form>
          </FormSection>
          <PrivacyPolicy />
          <div className="bottom-navigation">
            <p>
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default SignupForm;
