import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import { signupRequest, hideSignupRequestError } from '../signup/signup-slice';
import { acceptInvitationRequest } from '../invitation-signup/invitation-signup-slice';
import { AcceptInvitationRequestPayload } from '../../types/request-payload';
import SignupForm from './signup-form';

const mapStateToProps = (state: RootState) => ({
  status: state.signup.signupRequest.status,
  message: state.signup.signupRequest.message,
  error: state.signup.signupRequest.error,
  showError: state.signup.signupRequest.showError,
  trackingId: state.invitationSignup.trackingId,
  firstName: state.invitationSignup.firstName,
  lastName: state.invitationSignup.lastName,
  acceptInvitationRequestStatus:
    state.invitationSignup.acceptInvitationRequest.status,
});

const mapDispatchToProps = {
  hideError: () => hideSignupRequestError(),
  sendSignupRequest: (
    email: string,
    landingPage: string,
    timeZone: string,
    rewardfulReferralId: string,
  ) => signupRequest({ email, landingPage, timeZone, rewardfulReferralId }),
  sendAcceptInvitationRequest: (payload: AcceptInvitationRequestPayload) =>
    acceptInvitationRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    showVerificationCodeComponent?: () => void;
    forInvitation?: boolean;
    token?: string;
    email?: string;
    showPasswordValidationMessage?: boolean;
    showTermOfServiceAndPrivacyPolicy?: boolean;
    showLoginLink?: boolean;
    landingPage?: string;
    isLtdUser?: boolean;
  };

export default withRouter(connector(SignupForm));
